<template>
  <v-app style="overflow-y: hidden; overflow-x: hidden">
    <Bar></Bar>
    <v-main>
      <v-container fluid class="pa-0">
        <transition name="fade" mode="out-in">
          <PreLoader v-if="preLoaderDialog"></PreLoader>

          <router-view v-else-if="!preLoaderDialog"></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Bar from '@/components/bar'
import PreLoader from './views/home/preloader'

export default {
  name: 'App',

  components: { Bar, PreLoader },

  data() {
    return {}
  },

  computed: {
    preLoaderDialog() {
      return this.$store.state.preLoaderDialog
    },
  },
}
</script>

<style lang="scss">
@import './sass/app.scss';

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
