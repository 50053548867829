<template>
  <v-card elevation="0" id="pre-loader" class="rounded-0" flat color="#cbcbcb">
    <div class="" id="hero-text-1"></div>
    <div id="spinner" class="d-flex flex-column justify-center align-center">
      <div class="text-body-2 text-center mb-2">Loading</div>
      <v-progress-circular
        color="primary"
        size="30"
        width="1"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'pre-loader',

  data() {
    return {
      tl: null,
    }
  },

  methods: {
    animateWelcome() {
      const text = 'Welcome'
      let container = document.querySelector('#hero-text-1'),
        _sentenceEndExp = /(\.|\?|!)$/g

      let words = text.split(' '),
        wordCount = words.length,
        time = 0,
        word,
        element,
        duration,
        isSentenceEnd,
        i

      for (i = 0; i < wordCount; i++) {
        word = words[i]
        isSentenceEnd = _sentenceEndExp.test(word)

        element = container.appendChild(document.createElement('h3'))
        element.textContent = word

        duration = Math.max(0.5, word.length * 0.08)
        if (isSentenceEnd) {
          duration += 1
        }

        this.gsap.set(element, { autoAlpha: 0, scale: 0, z: 0.01 })

        this.tl
          .to(element, duration, { scale: 1.2, ease: 'slow(0.25, 0.9)' }, time)
          .to(
            element,
            duration,
            { autoAlpha: 1, ease: 'slow(0.25, 0.9, true)' },
            time
          )
        time += duration - 0.05
        if (isSentenceEnd) {
          time += 0.6
        }
      }

      this.tl.to('#pre-loader', {
        yPercent: -100,
        duration: 1,
        ease: 'power2.out',
        onComplete: this.onComplete,
      })
    },
    onComplete() {
      this.$store.commit('SET_PRE_LOADER_DIALOG', false)
    },
  },

  created() {
    this.tl = this.gsap.timeline({})
  },

  mounted() {
    this.animateWelcome()
  },
}
</script>

<style>
#pre-loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

#spinner {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

#hero-text-1 {
  width: 800px;
  position: absolute;
  top: calc(40%);
  left: calc(50% - 400px);
}

#hero-text-1 h3 {
  font-family: 'Asap', sans-serif;
  color: #706665;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  visibility: hidden;
  font-size: 120px;
  width: 800px;
  position: absolute;
  top: 25px;
}
</style>
