import { gsap } from 'gsap'
import { ExpoScaleEase, RoughEase, SlowMo } from 'gsap/EasePack'
import { TextPlugin } from 'gsap/TextPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'

gsap.registerPlugin(
  TextPlugin,
  ExpoScaleEase,
  RoughEase,
  SlowMo,
  ScrollTrigger,
  MotionPathPlugin,
  CSSRulePlugin
)

export { gsap, CSSRulePlugin }
