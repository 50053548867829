import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const options = {
  theme: {
    themes: {
      light: {
        primary: '#39b1a7',
        secondary: '#6c63ff',
        darkText: '#1e1e1e',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  breakpoint: {
    mobileBreakpoint: 'sm',
  },
}

export default new Vuetify(options)
