import Vue from 'vue'
import VueRouter from 'vue-router'

const home = () => import(/* webpackChunkName: "home" */ './../views/home')
const whatIDo = () =>
  import(/* webpackChunkName: "whatIDo" */ './../views/whatIDo/index')
const page404 = () =>
  import(/* webpackChunkName: "page404" */ '@/views/page404/page404')
const connect = () =>
  import(/* webpackChunkName: "connect" */ '@/views/connect')
const projects = () =>
  import(/* webpackChunkName: "projects" */ '@/views/projects')
const hero = () =>
  import(/* webpackChunkName: "projects" */ '@/views/home/hero/background.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '/home',
    name: 'home',
    component: home,
  },
  {
    path: '/what-i-do',
    name: 'whatIDo',
    component: whatIDo,
  },
  {
    path: '/connect',
    name: 'connect',
    component: connect,
  },
  {
    path: '/projects',
    name: 'projects',
    component: projects,
  },
  {
    path: '/hero',
    name: 'hero',
    component: hero,
  },
  {
    path: '/page404',
    name: 'page404',
    component: page404,
  },
  { path: '*', redirect: '/page404' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(/*to, from, savedPosition*/) {
    return { x: 0, y: 0 }
  },
})

export default router
