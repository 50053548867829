<template>
  <nav>
    <v-btn
      elevation="0"
      color=""
      x-large
      icon
      style="z-index: 20"
      id="menuToggle"
    >
      <div class="hamburger hamburger--spin" :class="{ 'is-active': menu }">
        <div class="hamburger-box">
          <div class="hamburger-inner"></div>
        </div>
      </div>
    </v-btn>

    <div class="" id="navigate-menu">
      <v-card class="rounded-0" width="100%" height="100%" color="#d2d2d2">
        <v-card-text>
          <div
            class="
              d-flex
              flex-column
              justify-center
              align-center
              pt-10
              navigate-items
            "
          >
            <div v-for="(item, i) in sections" :key="i" class="mb-10">
              <router-link
                class="
                  text-h5 text-md-h1
                  font-weight-bold
                  text-no-select
                  nav-section
                "
                :to="{ name: item.to }"
                active-class="active-section"
                @click.native="closeNavigate"
              >
                <span
                  class="
                    text-h5 text-md-h1
                    font-weight-bold
                    primary--text
                    me-2
                    asterisk
                  "
                >
                  *
                </span>
                {{ item.title }}
                <div class="under-border"></div>
              </router-link>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'bar',

  data() {
    return {
      menu: false,
      sections: [
        { title: 'Home', to: 'home' },
        { title: 'What I Do', to: 'whatIDo' },
        { title: 'Projects', to: 'projects' },
        { title: "Let's connect", to: 'connect' },
      ],
      tl: null,
    }
  },

  methods: {
    setup() {
      var body = document.body
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')
      var angle = Math.PI * 2
      var width, height

      document
        .getElementById('menuToggle')
        .addEventListener('click', (event) => {
          if (event.target === canvas) return

          body.appendChild(canvas)

          width = canvas.width = body.scrollWidth
          height = canvas.height = window.innerHeight

          var x = event.pageX
          var y = event.pageY
          var radius = maxDistance(x, y)

          var ripple = {
            alpha: 0,
            radius: 0,
            x: x,
            y: y,
          }

          this.menu = !this.menu

          this.gsap
            .timeline({
              onUpdate: drawRipple.bind(ripple),
              onComplete: removeCanvas,
            })
            .to(ripple, 0.4, { alpha: 1, radius: radius })
            .to('#navigate-menu', 0.5, {
              display: this.menu ? 'block' : 'none',
              alpha: this.menu ? 1 : 0,
            })
        })

      function removeCanvas() {
        body.removeChild(canvas)
      }

      function drawRipple() {
        ctx.clearRect(0, 0, width, height)
        ctx.beginPath()
        ctx.arc(this.x, this.y, this.radius, 0, angle, false)
        ctx.fillStyle = '#d2d2d2'
        ctx.fill()
      }

      function maxDistance(x, y) {
        var point = { x: x, y: y }
        var da = distanceSq(point, { x: 0, y: 0 })
        var db = distanceSq(point, { x: width, y: 0 })
        var dc = distanceSq(point, { x: width, y: height })
        var dd = distanceSq(point, { x: 0, y: height })
        return Math.sqrt(Math.max(da, db, dc, dd))
      }

      function distanceSq(p1, p2) {
        return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2)
      }
    },
    closeNavigate() {
      this.gsap
        .timeline({
          onComplete: () => {
            this.menu = false
          },
        })
        .to('#navigate-menu', 0.5, {
          display: 'none',
          alpha: 0,
        })
    },
  },

  mounted() {
    this.setup()
  },
}
</script>

<style>
#menuToggle {
  position: fixed;
  top: 10px;
  right: 10px;
}

#navigate-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 10;
}

.nav-section {
  text-decoration: none;
  width: 7rem;
}

.nav-section .under-border {
  width: 0px;
  height: 10px;
  margin-top: -15px;
  background: linear-gradient(270deg, #32ddb1, #325bdd);
  background-size: 400% 400%;

  -webkit-animation: gAnimate 4s ease infinite;
  -moz-animation: gAnimate 4s ease infinite;
  animation: gAnimate 4s ease infinite;
  transition: all 0.25s ease-in-out;
}

.nav-section:hover .under-border {
  width: 100%;
}

.nav-section .asterisk {
  display: none;
  transition: all 0.5s ease-in-out;
}

.active-section .asterisk {
  display: inline-block;
}

.nav-section {
  color: #666666 !important;
  transition: color 0.5s ease;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  cursor: default;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  background-color: #303030 !important;
  opacity: 0.5;
}

@-webkit-keyframes gAnimate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gAnimate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gAnimate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
