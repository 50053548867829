import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import { gsap, CSSRulePlugin } from './plugins/gsap'
import vuetify from './plugins/vuetify'

Vue.mixin({
  created: function () {
    this.gsap = gsap
    this.CSSRulePlugin = CSSRulePlugin
  },
})

import 'hamburgers/dist/hamburgers.min.css'

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
